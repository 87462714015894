import React, { useEffect } from "react"
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, CardTitle, Alert } from "reactstrap"
import MetaTags from 'react-meta-tags'
const { DateTime } = require("luxon");

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"

//import action
import { getStats as ongetStats } from "../../store/actions"

// Import Widgets
import MiniWidget from "./widgets/MiniWidget"

// Import number formatter
import { lovelaceConvert, numberFormat } from "../../helpers/number_helper"

// import { projectsList } from '../../constants/projects'

const singleProject = () => {
  const { storeID } = useParams()
  const { fetchStats } = useSelector(state => ({
    fetchStats: state.stats,
    statsLoading: state.statsLoading
  }))

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(ongetStats('Projects', storeID))
  }, [dispatch, storeID]);
  

  let TopSalePrice = "..."
  let TotalSales = "..."
  let TotalSalesRev = "..."
  let TopSaleDiff = ""
  let salesToday = ""
  let salesRevToday = ""
  let TopSalesData = []

  if (!fetchStats.statsLoading && fetchStats.stats[storeID]) {
    if(!fetchStats.stats[storeID].EmptyStats){
      const TotalStats = fetchStats.stats[storeID];
      TotalSales = numberFormat(TotalStats.TotalSales);
      TotalSalesRev = lovelaceConvert(TotalStats.TotalSalesRev);
      TopSalePrice = lovelaceConvert(TotalStats.TopSale.SalePrice);
      TopSalesData = TotalStats.TopSales;
      salesToday = TotalStats.TotalSalesToday;
      salesRevToday = lovelaceConvert(TotalStats.TotalSalesRevToday);
  
      // Difference
      TopSaleDiff = lovelaceConvert(TotalStats.TopSale.SalePrice - TotalStats.LastTopSale.SalePrice)
    }
  }


  function dateFormatter(cell, row) {
    if (row.DateTime) {
      const dt = DateTime.fromMillis(cell).toHTTP();
      const date = dt.split(' ').slice(0,4).join(' ')
      const time = dt.split(' ').slice(4,5).join(' ')
      return (
        <span>
          <strong>{date}</strong>
          <br/>
          {time} UTC
        </span>
      );
    }
  }

  function adaFormatter(cell, row) {
    if (row.SalePrice) {
      const ADA = lovelaceConvert(cell)
      return (
        <span>
          <strong> ₳ {ADA}</strong>
        </span>
      )
    }
  }

  function linkbuilder(cell, row) {
    if (row.AssetName) {
      const link = `https://tokhun.io/explore/${row.PolicyID}.${row.AssetName}`
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {cell}
        </a>
      )
    }
  }


  const columns = [{
    dataField: 'Name',
    text: 'Name',
    sort: true,
    formatter: linkbuilder
  }, {
    dataField: 'SalePrice',
    text: 'Sale Price',
    sort: true,
    formatter: adaFormatter,
  }, {
    dataField: 'DateTime',
    text: 'Date/Time',
    sort: true,
    formatter: dateFormatter,
  }];


  const defaultSorted = [{
    dataField: 'SalePrice',
    order: 'desc'
  }];


  const pageOptions = {
    sizePerPage: 10,
    totalSize: TopSalesData.length,
    custom: true,
  }

  // Mini Widgets 

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Largest Sale",
      value: `₳ ${TopSalePrice}`,
      badgeValue: `+ ₳ ${TopSaleDiff}`,
      color: "success",
      desc: "From previous",
    },
    {
      loading: fetchStats.statsLoading,
      icon: "bx bx-archive-in",
      title: "All Time Sales",
      value: TotalSales,
      badgeValue: `+ ${salesToday}`,
      color: "success",
      desc: "Since 00:00 UTC",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "All Time Sales Revenue",
      value: `₳ ${TotalSalesRev}`,
      badgeValue: `+ ₳ ${salesRevToday}`,
      color: "success",
      desc: " Since 00:00 UTC",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{storeID} Stats</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Projects" breadcrumbItem={storeID} />

          <Col>
            <Row>

              {/*mimi widgets */}
              <MiniWidget reports={reports} />
            </Row>
          </Col>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Top Sales</CardTitle>
                  <p className="card-title-desc">
                    This is the latest sales data from the {storeID} creators that we have, please bare in mind this can have a short delay.
                  </p>

                  <ToolkitProvider
                  bootstrap4
                    keyField='DateTime'
                    columns={columns}
                    data={TopSalesData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"DateTime"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />

                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">

                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

singleProject.propTypes = {
  match: PropTypes.any,
}

export default singleProject
