import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_SALES } from "./actionTypes"
import { getSalesSuccess, getSalesFail } from "./actions"

import { getFirebaseBackend } from "../../helpers/firebase_helper"
const fireBaseBackend = getFirebaseBackend()

function* fetchSales() {
  try {
    let response = yield call(fireBaseBackend.getObjectbyValue, {
      ref: 'Sales',
      orderByChild: 'SalePrice',
      limit: 10
    })
    console.log({response})
    yield put(getSalesSuccess(response))

  } catch (error) {
    yield put(getSalesFail(error))
  }
}

function* salesSaga() {
  yield takeEvery(GET_SALES, fetchSales)
}

export default salesSaga
