import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, Alert } from "reactstrap"
// import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
const isNil = require('lodash/at')

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

//redux
import { useSelector, useDispatch } from "react-redux"

//import action
import { getSearch as ongetSearch } from "../../store/actions"


const Dashboard = props => {
  const [isSearch, setSearch] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  let searchError = "";
  let searchKey = "asset_name_str";

  const { fetchSearch } = useSelector(state => ({
    fetchSearch: state.search,
    searchLoading: state.searchLoading
  }))

  console.log({ fetchSearch })

  const dispatch = useDispatch()
  useEffect(() => {
    searchError = "";
    if(isSearch){
      dispatch(ongetSearch(searchValue, searchKey));
      setSearch(false);
    }
  }, [isSearch]);

  let searchData = fetchSearch.search;

  if(searchData === false) {
    searchError = "Nothing found";
    searchData = [];
  }

  const columns = [{
    dataField: 'asset_name_str',
    text: 'Asset Name',
    sort: true
  }, {
    dataField: 'policy_id',
    text: 'Policy ID',
    sort: true,
  }, {
    dataField: 'quantity',
    text: 'Quantity',
    sort: true,

  }];

  const defaultSorted = [{
    dataField: 'asset_name_str',
    order: 'asc'
  }];


  const pageOptions = {
    sizePerPage: 10,
    totalSize: searchData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (searchData).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Cardano NFT spy</title>
        </MetaTags>
        <Container fluid>

          <Row className="justify-content-center mt-5">
            <Col lg="12">
              <div className="text-center">
                <h4 className="mt-5">Asset Search</h4>
                <p className="text-muted">
                  This probably wont work yet, but give it a go anyway
                </p>
                <p>
                  You can try searching for: Chibidango01350
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col lg="12">
              <div className="text-center">

                
                  <div className="form-group m-2">
                    <div className="input-group">
                      <input
                        value={searchValue}
                        onInput={e => setSearchValue(e.target.value)}
                        type="text"
                        className="form-control"
                        placeholder={("Search") + "..."}
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button 
                          onClick={() => setSearch(true)} 
                          className="btn btn-primary"
                          type="submit" >
                          { fetchSearch.searchLoading ? <i className="mdi mdi-magnify bx bx-spin "/>
                          : <i className="mdi mdi-magnify"/> }
                          
                        </button>
                      </div>
                    </div>
                  </div>
              
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 m-2">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Assets</CardTitle>


                  <ToolkitProvider
                    keyField='asset'
                    columns={columns}
                    data={searchData}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />

                            </div>
                              { searchError != "" &&
                                <Alert color="warning" role="alert">{searchError}</Alert>
                              }
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">

                        </Row>
                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any,
// }

export default Dashboard;
