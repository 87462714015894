import { GET_STATS_SUCCESS, GET_STATS_FAIL } from "./actionTypes"

const INIT_STATE = {
  stats: [],
  error: {},
  statsLoading: true
}

const stats = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          [action.payload.storeID]: {
            ...action.payload.Totals,
             TopSales: action.payload.TopSales,
             EmptyStats: action.payload.EmptyStats
            }},
        statsLoading: false
      }

    case GET_STATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default stats
