import { GET_SEARCH, GET_SEARCH_SUCCESS, GET_SEARCH_FAIL } from "./actionTypes"

const INIT_STATE = {
  search: [],
  error: {},
  searchLoading: false
}

const search = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SEARCH:
      return {
        ...state,
        searchLoading: true,
      }
    case GET_SEARCH_SUCCESS:
      return {
        ...state,
        search: action.payload,
        searchLoading: false
      }

    case GET_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
        searchLoading: false
      }

    default:
      return state
  }
}

export default search
