import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import {
    Container,
    Row,
    Col,
} from "reactstrap"

import { projectsList } from '../../constants/projects'

const Projects = () => {
    let history = useHistory();
    const [search, setSearch] = useState(false)
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            history.push(`/projects/${projectsList[search]}`);
        }
    }
    const handleOnClick = () => {
        history.push(`/projects/${projectsList[search]}`);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Projects | Cardano NFT spy</title>
                </MetaTags>
                <Container fluid>

                    <Row className="justify-content-center mt-5">
                        <Col lg="8">
                            <div className="text-center">
                                <h4 className="mt-5">Search for your favourite creators</h4>
                                <p className="text-muted">
                                    This site is still in beta so some projects may not show as you expect
                                </p>
                            </div>
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    list="datalistOptions"
                                    id="exampleDataList"
                                    placeholder="Type to search..."
                                    onKeyDown={e => { handleKeyDown(e) }}
                                    onChange={s => setSearch(s.target.value)}
                                />
                                <datalist id="datalistOptions">
                                    {Object.entries(projectsList).map(item => {
                                        return <option key={item[1]} value={item[0]} />
                                    })}
                                </datalist>
                                <button
                                    onClick={() => handleOnClick()}
                                    className="btn btn-primary p-3"
                                    type="submit" >
                                    <i className="mdi mdi-magnify" />

                                </button>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}




export default Projects;
