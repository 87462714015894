import React from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Table } from "reactstrap"

const AddSalesAPIDoc = () => {

    const bodyExample = {
        "sale_id": {
            "assetid": "e472ce79b3d28775b705a38cc1b70dafc49edb78c79c6404e0291f3a417175616d616e43696e656d6174696356657273696f6e",
            "sale_date": "1629348946049",
            "sale_price": "8000000",
            "sale_type": "marketplace"
        },
        "sale_id2": {
            "assetid": "e472ce79b3d28775b705a38cc1b70dafc49edb78c79c6404e0291f3a417175616d616e43696e656d6174696356657273696f6e",
            "sale_date": "1629348946049",
            "sale_price": "8000000",
            "sale_type": "marketplace",

            "item_name": "Asset name (optional)",
            "listed_date": "8000000",
            "multi_sale": false
        },
        "sale_id3": {
            "assetid": "e472ce79b3d28775b705a38cc1b70dafc49edb78c79c6404e0291f3a417175616d616e43696e656d6174696356657273696f6e",
            "sale_date": "1629348946049",
            "sale_price": "8000000",
            "sale_type": "marketplace"
        },
        "sale_id4": {
            "assetid": "e472ce79b3d28775b705a38cc1b70dafc49edb78c79c6404e0291f3a417175616d616e43696e656d6174696356657273696f6e",
            "sale_date": "1629348946049",
            "sale_price": "8000000",
            "sale_type": "marketplace",

            "item_name": "Asset name (optional)",
            "listed_date": "8000000",
            "multi_sale": false
        }
    }

    return (
        <React.Fragment>
            <p>
                The addSales endpoint allow you to add sales into our system for your marketplace.
            </p>
            <p>
                <b>Staging Endpoint:</b> https://us-central1-cnft-spy-staging.cloudfunctions.net/api/v1/addSales
                <br />
                Live Endpoint:
            </p>

            <Col lg="12">
                <Card outline color="success" className="border text-white-50">
                    <CardBody className="text-white">
                        <CardTitle className="mb-4 text-success">
                            <i className="mdi mdi-alert-circle-outline me-3" />
                            Example Body
                        </CardTitle>
                        <CardText className="">
                        </CardText>
                        <pre>{JSON.stringify(bodyExample, null, 2)}</pre>
                    </CardBody>
                </Card>
            </Col>

            <Col lg="12">
                <h5>Reference</h5>
                <div className="table-responsive">
                    <Table className="table mb-0 table-dark">
                        <thead>
                            <tr>
                                <th>Element</th>
                                <th>Data Type</th>
                                <th>Required</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">sale_id</th>
                                <td>Your unique sale identifier</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">assetid</th>
                                <td>Concatination of policy ID + hex encoded assetname</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">sale_date</th>
                                <td>Timestamp of sale in unix milliseconds</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">sale_price</th>
                                <td>Total sale price in lovelace</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">sale_type</th>
                                <td>Type of sale marketplace/auction</td>
                                <td>Yes</td>
                            </tr>
                            <tr>
                                <th scope="row">listed_date</th>
                                <td>Timestamp of sales listing in unix milliseconds</td>
                                <td>Optional</td>
                            </tr>
                            <tr>
                                <th scope="row">item_name</th>
                                <td>Asset Name</td>
                                <td>Optional</td>
                            </tr>
                            <tr>
                                <th scope="row">main_ipfs</th>
                                <td>{"Main IPFS hash of token sold (prepend with 'ipfs://')"}</td>
                                <td>Optional</td>
                            </tr>
                            <tr>
                                <th scope="row">multi_sale</th>
                                <td>Set to True if the sale was multiple items</td>
                                <td>Optional</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </Col>
        </React.Fragment>
    )
}

export default AddSalesAPIDoc;