import { call, put, takeEvery } from "redux-saga/effects"
const isNil = require('lodash/isNil')

// Crypto Redux States
import { GET_STATS } from "./actionTypes"
import { getStatsSuccess, getStatsFail } from "./actions"

import { getFirebaseBackend } from "../../helpers/firebase_helper"
const fireBaseBackend = getFirebaseBackend()

function* fetchStats(params) {
  try {
    let response = yield call(fireBaseBackend.getObject, {
      ref: `Stats/${params.statsType}/${params.storeID}`,
    })

    let EmptyStats = false;
    let TopSales = {};
    if(!isNil(response)){
      TopSales = Object.keys(response.TopSales).map((k) => response.TopSales[k])
    } else {
      EmptyStats = true;
      TopSales = false;
    }

    const payload = {...response, EmptyStats: EmptyStats, TopSales: TopSales, storeID: params.storeID}
    yield put(getStatsSuccess(payload))

  } catch (error) {
    yield put(getStatsFail(error))
  }
}

function* statsSaga() {
  yield takeEvery(GET_STATS, fetchStats)
}

export default statsSaga
