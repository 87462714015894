import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  UncontrolledAlert
} from "reactstrap"

const FileRightBar = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <React.Fragment>
      <Card className="filemanager-sidebar me-md-2">
        <CardBody>
          <div className="d-flex flex-column h-100">
            <div className="mb-4">
            
              <ul className="list-unstyled categories-list">
               
                <li>
                  <Link to="../apidocs/auth" className="text-body d-flex align-items-center">
                    <i className="mdi mdi-lock-check font-size-16 text-primary me-2"></i>{" "}
                    <span className="me-auto">Authentication</span>
                  </Link>
                </li>
                <li>
                  <Link to="../apidocs/addSales" className="text-body d-flex align-items-center">
                    <i className="mdi mdi-tag-plus font-size-16 me-2 text-success"></i>{" "}
                    <span className="me-auto">addSales</span>
                  </Link>
                </li>
             
              </ul>
            </div>

            <div className="mt-auto">
              <UncontrolledAlert color="success" className="px-3 mb-0 alert-dismissible">
                <div className="mb-3">
                  <i className="bx bxs-folder-open h1 text-success"></i>
                </div>

                <div>
                  <h5 className="text-success">Do you run a marketplace</h5>
                  <p>We want your data, come talk to us</p>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none text-success"
                    >
                      Contact us <i className="mdi mdi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </UncontrolledAlert>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default FileRightBar
