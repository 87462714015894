import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Alert, Input } from "reactstrap"
import MetaTags from 'react-meta-tags';
const BigNumber = require('bignumber.js');
const { DateTime } = require("luxon");

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

//redux
import { useSelector, useDispatch } from "react-redux"

//import action
import { getSearch as ongetSearch } from "../../store/actions"

// Import number formatter
import { lovelaceConvert } from "../../helpers/number_helper"


const Dashboard = () => {
  const [isSearch, setSearch] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [searchKey, setSearchKey] = useState("PolicyID")
  let searchError = "";
  let searchType = "sales"

  const { fetchSearch } = useSelector(state => ({
    fetchSearch: state.search,
    searchLoading: state.searchLoading
  }))

  const dispatch = useDispatch()
  useEffect(() => {
    searchError = "";
    if (isSearch) {
      dispatch(ongetSearch(searchValue, searchType, searchKey));
      setSearch(false);
    }
  }, [isSearch]);

  let searchData = fetchSearch.search;

  if (searchData === false) {
    searchError = "Nothing found";
    searchData = [];
  }

  function dateFormatter(cell, row) {
    if (row.DateTime) {
      const dt = DateTime.fromMillis(cell).toHTTP();
      const date = dt.split(' ').slice(0, 4).join(' ')
      const time = dt.split(' ').slice(4, 5).join(' ')
      return (
        <span>
          <strong>{date}</strong>
          <br />
          {time}
        </span>
      );
    }
  }

  function adaFormatter(cell, row) {
    if (row.SalePrice) {
      const ADA = lovelaceConvert(cell)
      return (
        <span>
          <strong> ₳ {ADA}</strong>
        </span>
      )
    }
  }


  function linkbuilder(cell, row) {
    if (row.AssetName) {
      const link = `https://tokhun.io/explore/${row.PolicyID}.${row.AssetName}`
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {cell}
        </a>
      )
    }
  }


  const columns = [{
    dataField: 'AssetName',
    text: 'Asset Name',
    sort: true,
    formatter: linkbuilder,
    style: { 'width': '125px' }
  }, {
    dataField: 'SalePrice',
    text: 'Sale Price',
    sort: true,
    formatter: adaFormatter
  }, {
    dataField: 'DateTime',
    text: 'Date/Time',
    sort: true,
    formatter: dateFormatter
  }, {
    dataField: 'Marketplace',
    text: 'Marketplace',
    sort: true
  }, {
    dataField: 'SaleType',
    text: 'Sale Type',
    sort: true
  },];

  const defaultSorted = [{
    dataField: 'DateTime',
    order: 'desc',
  }];


  const pageOptions = {
    sizePerPage: 10,
    totalSize: searchData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (searchData).length }];


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Cardano NFT spy</title>
        </MetaTags>
        <Container fluid>

          <Row className="justify-content-center">
            <Col lg="12">
              <div className="text-center">
                <h1 className="mt-5">Sales Search</h1>
                <p className="text-muted">
                  Not all marketplace data is available yet as we are sill in beta, but give it a go anyway
                </p>
              </div>
            </Col>
          </Row>


          <Row className="justify-content-center mt-5">
            <Col lg="12">
              <div className="text-center ">
                <Row>
                  <Col md={2} className="mb-2">
                    <select className="form-select p-3"
                      value={searchKey}
                      onChange={e => setSearchKey(e.target.value)}
                    >
                      <option value="PolicyID">Policy ID</option>
                      <option value="AssetName">Asset Name</option>
                      <option value="AssetID">Asset ID</option>
                    </select>
                  </Col>
                  <Col md={9} className="mb-2">
                    <Input
                      value={searchValue}
                      onInput={e => setSearchValue(e.target.value)}
                      type="text"
                      className="form-control p-3"
                      placeholder={("Search") + "..."}
                      aria-label="Recipient's username"
                    />
                  </Col>
                  <Col md={1}>
                    <button
                      onClick={() => setSearch(true)}
                      className="btn btn-primary p-3"
                      type="submit" >
                      {fetchSearch.searchLoading ? <i className="mdi mdi-magnify bx bx-spin " />
                        : <i className="mdi mdi-magnify" />}

                    </button>

                  </Col>
                </Row>

              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 mt-4">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Assets</CardTitle>


                  <ToolkitProvider
                    bootstrap4
                    keyField='DocKey'
                    columns={columns}
                    data={searchData}
                  >
                    {toolkitProps => (
                      <React.Fragment>

                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"DocKey"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap"
                                }
                                headerClasses={"fixedwidth"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />

                            </div>
                            {searchError != "" &&
                              <Alert color="warning" role="alert">{searchError}</Alert>
                            }
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-30">

                        </Row>

                      </React.Fragment>
                    )
                    }
                  </ToolkitProvider>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg="8">
              <Card outline color="info" className="border text-white-50">
                <CardBody>
                  <CardTitle className="mb-4 text-info">
                    <i className="mdi mdi-alert-circle-outline me-3" />
                    How our search works
                  </CardTitle>
                  <CardText className="">
                    <span>
                      Currently our search only supports these parameters, we are working on making this better.
                    </span>
                    <br />
                    <br />
                    <b className="text-white">Policy ID:</b> Full Policy ID of the token/s
                    <br />
                    <b className="text-white">Asset Name:</b> Exact string of the token name (Asset Name) no spaces or special characters
                    <br />
                    <b className="text-white">Asset ID:</b> Policy ID and hex-encoded AssetName joined together (not the asset fingerprint)
                    <br />
                    <br />
                    <span>Examples:</span><br />
                    <b className="text-white">Policy ID:</b> ac26608004bca3e44ebc8f28fd58d4ae0dc26ffa9baeb8b3e32ee4cf
                    <br />
                    <b className="text-white">Asset Name:</b> RetroBytes1I05
                    <br />
                    <b className="text-white">Asset ID:</b> ceb5dedd6cda3f0b4a98919b5d3827e15e324771642b57e0e6aabd574c6f76656c61636530303539
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard;
