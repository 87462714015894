import { GET_SALES, GET_SALES_FAIL, GET_SALES_SUCCESS } from "./actionTypes"

export const getSales = () => ({
  type: GET_SALES,
})

export const getSalesSuccess = sales => ({
  type: GET_SALES_SUCCESS,
  payload: sales,
})

export const getSalesFail = error => ({
  type: GET_SALES_FAIL,
  payload: error,
})
