import React from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Alert, Input } from "reactstrap"

const AuthAPIDoc = () => {

    return (
        <React.Fragment>

            <p>
                Our API usage is retricted to verified marketplaces and specific partners.
                If you think your organisation would benifit from using our API please get contact with us.
            </p>
            <p>
                All POST requests expect a data payload to be sent as the HTTP body, together with specific header values.
                The request payload is always JSON format, which should be specified using the Content-Type header.
                JSON does not enforce element order and any client implementation must not assume an order of data.
            </p>

            <Col lg="12">
                <Card outline color="info" className="border text-white-50">
                    <CardBody className="text-white">
                        <CardTitle className="mb-4 text-info">
                            <i className="mdi mdi-alert-circle-outline me-3" />
                            Example Headers
                        </CardTitle>
                        <CardText className="">
                        </CardText>
                        <b>Content-Type:</b> application/json <br />
                        <b>Account:</b> cspy<br />
                        <b>Authorization:</b> YWRhLmxvdmVsYWNlQGNhcmRhbm9uZnRzcHkuY29tOnRoaXNpc2FmYWtlcGFzc3dvcmQ=<br />

                    </CardBody>
                </Card>
            </Col>

            <h6>Account</h6>
            <p>
                A short text string of your Account ID that has been provided
            </p>

            <h6>Authorization</h6>
            <p>
                A base64 encoding of your account email address together with your chosen password seperated with a colon (:)
            </p>
            <p>
                *Please note Authorization is seperate for staging site and live site 
            </p>
            <p>
                If you need to reset your API password please contact us
            </p>



        </React.Fragment>
    )
}

export default AuthAPIDoc;