import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

// Redux Store
import { toggleLeftmenu } from "../../store/actions"

const Navbar = props => {

  const [market, setmarket] = useState(false)
  const [projects, setprojects] = useState(false)

  function leftMenu() {
    props.leftMenu = false;
  }

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/dashboard"
                    onClick={() => {
                      props.toggleLeftmenu(false)
                    }}
                  >
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Dashboard")}
                  </Link>

                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/marketplaces"
                    onClick={e => {
                      e.preventDefault()
                      setmarket(!market)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2"></i>
                    {props.t("Marketplaces")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: market })}
                  >
                    <Row>
                      <Col lg={12}>
                        <div>
                          <Link to="/marketplaces/cnft" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("CNFT.io")}
                          </Link>
                          <Link to="/marketplaces/tokhun" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Tokhun.io")}
                          </Link>
                          <Link to="/comingsoon" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Artano.io")}
                          </Link>
                          <Link to="/comingsoon" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Xhibit")}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/projects"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setprojects(!projects)
                    }}
                  >
                    <i className="bx bx-tone me-2"></i>
                    {props.t("Projects")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: projects })}
                  >
                    <Row>
                      <Col lg={12}>
                        <div>
                          <Link to="/projects" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Search Projects")}
                          </Link>
                          <Link to="/projects/retronfts" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("RetroNFTs")}
                          </Link>
                          <Link to="/projects/thehoskinsons" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("The Hoskinsons")}
                          </Link>
                          <Link to="/projects/spacebudz" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Space Budz")}
                          </Link>
                          <Link to="/projects/clay-mates" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Claymates")}
                          </Link>
                          <Link to="/projects/beyondrockets" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Beyond Rockets")}
                          </Link>
                          <Link to="/projects/unsigned-algorithms" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Unsigned Algorithms")}
                          </Link>
                          <Link to="/projects/zombits" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Zombits")}
                          </Link>
                          <Link to="/projects/rhino-generation" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Rhino Generation")}
                          </Link>
                          <Link to="/projects/cardanobeans" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Cardano Beans")}
                          </Link>
                          <Link to="/projects/cryptoknitties" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("CryptoKnitties")}
                          </Link>
                          <Link to="/projects/cardanotrees" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Cardano Trees")}
                          </Link>
                          <Link to="/projects/cardanoapes" className="dropdown-item"
                            onClick={() => {
                              props.toggleLeftmenu(false)
                            }}>
                            {props.t("Cardano Apes")}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>

                {/* <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setapp(!app)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-customize me-2"></i>
                    {props.t("Escrows")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("CNFT (Discord)")}
                    </Link>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("CardanoNFT (Discord)")}
                    </Link>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("Hoskinsons (Discord)")}
                    </Link>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("Llama (Discord)")}
                    </Link>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("CryptoFly (Discord)")}
                    </Link>
                    <Link to="./comingsoon" className="dropdown-item"
                      onClick={() => {
                        props.toggleLeftmenu(false)
                      }}>
                      {props.t("Cryptoverse (Discord)")}
                    </Link>

                  </div>
                </li> */}


              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar))
)
