// Converts Lovelace to ADA & Numbers to internation formats

const BigNumber = require('bignumber.js');

function lovelaceConvert(lovelace) {
    const ada = new BigNumber(lovelace).times(0.000001).div(1).toString(10)
    const output = new Intl.NumberFormat().format(ada)
    return output
}

function numberFormat(num) {
    const output = new Intl.NumberFormat().format(num)
    return output
}

export { lovelaceConvert, numberFormat }