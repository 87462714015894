import React from "react"
import { Link } from "react-router-dom"
import {
  Col,
  Row,
} from "reactstrap"

// Documents
import AuthAPIDoc from "./Docs/authAPIDoc"
import AddSalesAPIDoc from "./Docs/addSalesAPIDoc"

const FileList = (doc) => {
  let title = "Authentication"
  let document = <AuthAPIDoc/>

  switch (doc.doc) {
    case "auth":
      title = "Authentication";
      document = <AuthAPIDoc/>;
      break;

    case "addSales":
      title = "addSales Endpoint"
      document = <AddSalesAPIDoc/>;
      break;
      
    default:
      document = <AuthAPIDoc/>
      break;
  }
  return (
    <React.Fragment>
      <div>
        <Row className="mb-3">
          <Col xl={3} sm={6}>
            <div className="mt-2">
              <h5>{title}</h5>
            </div>
          </Col>
          <Col xl={9} sm={6}>

          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {document}
        </Row>
      </div>
    </React.Fragment>
  )
}

export default FileList
