import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//Marketplaces Pages
import cnftPage from "../pages/Marketplaces/cnft"
import tokhunPage from "../pages/Marketplaces/tokhun"

// Projects
import Projects from "../pages/Projects/index"
import SingleProject from "../pages/Projects/singleProject"

// Dashboard
// import Dashboard from "../pages/Dashboard/comingSoon"
import ComingSoon from "../pages/Dashboard/comingSoon"
import AssetSearch from "../pages/Dashboard/assetSearch"
import SalesSearch from "../pages/Dashboard/salesSearch"


// APIDocs
import APIDocs from "../pages/APIDocs/index"

const authProtectedRoutes = [
  // //profile
  { path: "/profile", component: UserProfile },

]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }, 

  //new Routes
  { path: "/dashboard", component: SalesSearch },
  // { path: "/assetsearch", component: AssetSearch },
  // { path: "/salessearch", component: SalesSearch },

  // Marketplace Pages
  { path: "/marketplaces/cnft", component: cnftPage },
  { path: "/marketplaces/tokhun", component: tokhunPage },

  // Projects
  { path: "/projects", component: Projects },
  { path: "/projects/:storeID", component: SingleProject },


  // coming soon
  { path: "/comingsoon", component: ComingSoon },
  { path: "/comingsoon/:id", component: ComingSoon },

  { path: "/apidocs", component: function apiDocs() { return <APIDocs doc="auth" />}},
  { path: "/apidocs/auth", component: function apiDocs() { return <APIDocs doc="auth" />}},
  { path: "/apidocs/addSales", component: function apiDocs() { return <APIDocs doc="addSales" />}},

  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  // 404 
  { path: "*", component: ComingSoon },
]

export { publicRoutes, authProtectedRoutes }
