const projectsList = {
    '₳CPhoto': '-cphoto',
    '$HOSK by The Hoskinsons': '-hosk-by-the-hoskinsons',
    '∆Possibilities': '-possibilities',
    '∆Possibilities_🤍': '-possibilities-',
    '∆Possibilities_|Bio': '-possibilities-bio',
    '∆Possibilities_Independence': '-possibilities-independence',
    '∆|Usagi Koto_Chiru_°🌹': '-usagi-koto-chiru-',
    '∆|Usagi koto_spotlight': '-usagi-koto-spotlight',
    'Absolute units': 'absolute-units',
    'ADA ColorFields': 'ada-colorfields',
    'Ada Dolls': 'ada-dolls',
    'Ada Lego': 'ada-lego',
    'Ada-Monsterz': 'ada-monsterz',
    'ADA Mountain Project': 'ada-mountain-project',
    'ADA Ofice Bits': 'ada-ofice-bits',
    'Ada Power': 'ada-power',
    ADAastrology: 'adaastrology',
    ADAbirdsNFT: 'adabirdsnft',
    AdaBots: 'adabots',
    AdaCards: 'adacards',
    ADACoinsNFT: 'adacoinsnft',
    ADADAISY: 'adadaisy',
    'ADAERGnomes: Cross-Chain Legends': 'adaergnomes-cross-chain-legends',
    AdaHitz: 'adahitz',
    ADAJam: 'adajam',
    AdaMiners: 'adaminers',
    Adamobile: 'adamobile',
    Adamon: 'adamon',
    AdaNerds: 'adanerds',
    ADAngelos: 'adangelos',
    ADAnimals: 'adanimals',
    ADAofficeBits: 'adaofficebits',
    ADAplanet: 'adaplanet',
    ADARacers: 'adaracers',
    ADARocks: 'adarocks',
    AdaSenshiNinja: 'adasenshininja',
    AdaverseX: 'adaversex',
    'Add Dystoblock': 'add-dystoblock',
    'ADD ETH KILLERS': 'add-eth-killers',
    'Added dystoblock': 'added-dystoblock',
    'Added FANCYBOYOWNERSCLUB': 'added-fancyboyownersclub',
    'Added ProjectDystoblock': 'added-projectdystoblock',
    'Added Son of Sam': 'added-son-of-sam',
    'Adding Cardanime Coins': 'adding-cardanime-coins',
    Aeoniumsky: 'aeoniumsky',
    'Aether Crypto': 'aether-crypto',
    'Aether Crypto + Augmented Life': 'aether-crypto-augmented-life',
    Aidali: 'aidali',
    Alebrijes: 'alebrijes',
    AlexDeMarcos: 'alexdemarcos',
    AlineDias: 'alinedias',
    Alpha: 'alpha',
    AmoraCNFT: 'amoracnft',
    anim8bits: 'anim8bits',
    'Anim8bits x Backwards Geometry': 'anim8bits-x-backwards-geometry',
    'Anton Gudim': 'anton-gudim',
    artaphakt: 'artaphakt',
    ArtGallery_AI: 'artgallery-ai',
    AstroBunz: 'astrobunz',
    'Augmented Life': 'augmented-life',
    'Baby Monkeys by CardanoApes': 'baby-monkeys-by-cardanoapes',
    'Backwards Geometry': 'backwards-geometry',
    'badass_heroes.json': 'badass-heroes-json',
    BattleCats: 'battlecats',
    Berry: 'berry',
    BeyondRockets: 'beyondrockets',
    BIGDBRO: 'bigdbro',
    Bitlands: 'bitlands',
    BitMania: 'bitmania',
    BitMarks: 'bitmarks',
    'BitPunk!': 'bitpunk-',
    BitsServe: 'bitsserve',
    'Bizarre Donut Prototype': 'bizarre-donut-prototype',
    BizarreStars: 'bizarrestars',
    BlizzEffect: 'blizzeffect',
    BlockchainEvolution: 'blockchainevolution',
    'Boner!': 'boner-',
    Bonzaibits: 'bonzaibits',
    BrownNFT: 'brownnft',
    Bstr: 'bstr',
    'BT&C': 'bt-c',
    BubbleGoblin: 'bubblegoblin',
    BusyBeesCNFT: 'busybeescnft',
    ButtworkNFT: 'buttworknft',
    Cadabears: 'cadabears',
    Cadagirls: 'cadagirls',
    'Cadambabo NFTs': 'cadambabo-nfts',
    'Call Me By Your Name by Chadi Nassar': 'call-me-by-your-name-by-chadi-nassar',
    Canuckz: 'canuckz',
    Cardacity: 'cardacity',
    CardaGnomes: 'cardagnomes',
    CardAmigos: 'cardamigos',
    Cardamoji: 'cardamoji',
    Cardania: 'cardania',
    'Cardanian Snowglobes': 'cardanian-snowglobes',
    Cardanians: 'cardanians',
    'Cardanime Stories Special EURO2020': 'cardanime-stories-special-euro2020',
    'Cardanime Stories "The blind girl"': 'cardanime-stories-the-blind-girl-',
    CardanimeSToriesEpisodes: 'cardanimestoriesepisodes',
    'CARDANO BULL & BIRDS': 'cardano-bull-birds',
    'Cardano Buzz': 'cardano-buzz',
    'Cardano Caricatures': 'cardano-caricatures',
    'Cardano Cat Club': 'cardano-cat-club',
    'Cardano FC': 'cardano-fc',
    'Cardano Flowers': 'cardano-flowers',
    'CARDANO ICONS': 'cardano-icons',
    'Cardano Kidz': 'cardano-kidz',
    'Cardano Kings': 'cardano-kings',
    'Cardano Kings and Queens': 'cardano-kings-and-queens',
    'Cardano Kombat': 'cardano-kombat',
    'Cardano Pepe': 'cardano-pepe',
    'Cardano Waifus': 'cardano-waifus',
    cardanoapes: 'cardanoapes',
    'Cardanoapes 3D': 'cardanoapes-3d',
    CardanoBeans: 'cardanobeans',
    Cardanobeasts: 'cardanobeasts',
    CardanoBits: 'cardanobits',
    CardanoChrome: 'cardanochrome',
    CardanoCity: 'cardanocity',
    CardanoComics: 'cardanocomics',
    CardanoCountries: 'cardanocountries',
    CardanoDan: 'cardanodan',
    CardanoDaughters: 'cardanodaughters',
    cardanoDIKS: 'cardanodiks',
    CardanoGirl: 'cardanogirl',
    CardanoGods: 'cardanogods',
    CardanoKeys: 'cardanokeys',
    CardanoKiwis: 'cardanokiwis',
    CardanoMonsters: 'cardanomonsters',
    CardanoPotions: 'cardanopotions',
    CardanoPuzzleNFT: 'cardanopuzzlenft',
    CardanoRobin: 'cardanorobin',
    CardanoRx: 'cardanorx',
    CardanoSpace: 'cardanospace',
    CardanoTakeover: 'cardanotakeover',
    CardanoTrees: 'cardanotrees',
    cardanowaifus: 'cardanowaifus',
    CardanoWarriors: 'cardanowarriors',
    CardanoWoman: 'cardanowoman',
    CardanoZodiacRings: 'cardanozodiacrings',
    Cardatoons: 'cardatoons',
    cardinos: 'cardinos',
    Cardoggos: 'cardoggos',
    catloaf: 'catloaf',
    ChaoticMathematics: 'chaoticmathematics',
    'Charles Gitnick': 'charles-gitnick',
    Chessano: 'chessano',
    'Chibidango Heroes': 'chibidango-heroes',
    Chillix: 'chillix',
    Cicada: 'cicada',
    'Clay Mates': 'clay-mates',
    'Clay Nation by Clay Mates': 'clay-nation-by-clay-mates',
    CNFTed: 'cnfted',
    'Co-Op': 'co-op',
    CocoLoco: 'cocoloco',
    Collabotrotters: 'collabotrotters',
    'Colored Coins': 'colored-coins',
    'Colorful Screens': 'colorful-screens',
    CoopLegends: 'cooplegends',
    'Corn n Friends': 'corn-n-friends',
    'Corn n Friends x SwankPie x Dan': 'corn-n-friends-x-swankpie-x-dan',
    'Create Cardano Budz': 'create-cardano-budz',
    'Create dystoblock': 'create-dystoblock',
    CreepyBallZ: 'creepyballz',
    criminalpunks: 'criminalpunks',
    cryp7ocr3ations: 'cryp7ocr3ations',
    Crypties: 'crypties',
    'Crypto Girls CNFT': 'crypto-girls-cnft',
    'Crypto Mind': 'crypto-mind',
    'Crypto Sports': 'crypto-sports',
    cryptocockroach: 'cryptocockroach',
    CryptoCommander: 'cryptocommander',
    'Cryptodelic Art': 'cryptodelic-art',
    'Cryptodelic-NFrogTs': 'cryptodelic-nfrogts',
    CryptoDino: 'cryptodino',
    CryptoDoggies: 'cryptodoggies',
    Cryptofly777: 'cryptofly777',
    CryptoHeroez: 'cryptoheroez',
    CryptoKeens: 'cryptokeens',
    cryptoknitties: 'cryptoknitties',
    CryptoMayor: 'cryptomayor',
    CryptoMS: 'cryptoms',
    CryptoPetz: 'cryptopetz',
    CryptoPhages: 'cryptophages',
    CryptoRev123: 'cryptorev123',
    CryptoStamps: 'cryptostamps',
    CryptoSugarSkullsNFT: 'cryptosugarskullsnft',
    CrytpoFruit: 'crytpofruit',
    CutisaurusPark: 'cutisauruspark',
    Cyberbots: 'cyberbots',
    Cyberbots_4K_Wallpapers: 'cyberbots-4k-wallpapers',
    CyberPunks: 'cyberpunks',
    Cypherkicks: 'cypherkicks',
    'Da Wanted': 'da-wanted',
    DadADAJokes: 'dadadajokes',
    DailyDose: 'dailydose',
    Daniboy_NFTs: 'daniboy-nfts',
    'Darke Element': 'darke-element',
    DEADPXLZ: 'deadpxlz',
    'Derp Birds': 'derp-birds',
    DesertStories: 'desertstories',
    DexoWorlds: 'dexoworlds',
    DinoGalaxy: 'dinogalaxy',
    'DM Photography': 'dm-photography',
    'Dogs on the Chain': 'dogs-on-the-chain',
    Dragonbazzey: 'dragonbazzey',
    DrunkenDragon: 'drunkendragon',
    DustyNFTs: 'dustynfts',
    Elamite_Art: 'elamite-art',
    Encryptants: 'encryptants',
    Enpimony: 'enpimony',
    EntheosAI: 'entheosai',
    Fabulart: 'fabulart',
    Fahadouken3D: 'fahadouken3d',
    FANCYBOYOWNERSCLUB: 'fancyboyownersclub',
    FemmeFatale: 'femmefatale',
    FernandoTorelli: 'fernandotorelli',
    'Fine Art by Frank Hampus Weslien': 'fine-art-by-frank-hampus-weslien',
    FingerMonsters: 'fingermonsters',
    'Floating Thunder': 'floating-thunder',
    'flow by MarshallChow': 'flow-by-marshallchow',
    FlueFrog: 'fluefrog',
    Fonnk: 'fonnk',
    'forest-tokens': 'forest-tokens',
    FrankHampusWeslien: 'frankhampusweslien',
    frederico4d: 'frederico4d',
    'Frigid Optics': 'frigid-optics',
    GANbin: 'ganbin',
    GANbot: 'ganbot',
    'Garlic Soul Art': 'garlic-soul-art',
    GlitchCreationCNFT: 'glitchcreationcnft',
    Glitchsig: 'glitchsig',
    Glus: 'glus',
    'GoldenBoy NFT': 'goldenboy-nft',
    GoldenPurritoCoins: 'goldenpurritocoins',
    gorillAPEz: 'gorillapez',
    'Grandmaster Adventurers': 'grandmaster-adventurers',
    GTTDQMMRNG: 'gttdqmmrng',
    'Guardians of Cardano': 'guardians-of-cardano',
    GUERT: 'guert',
    HashGuardians: 'hashguardians',
    HeadsofCards: 'headsofcards',
    herkimer: 'herkimer',
    'Hide! Series': 'hide-series',
    HighSteaksNFT: 'highsteaksnft',
    Hogsters: 'hogsters',
    'Hosk Pool by The Hoskinsons': 'hosk-pool-by-the-hoskinsons',
    HUGEDBRO: 'hugedbro',
    'Hydra Cardano': 'hydra-cardano',
    HYPESkulls: 'hypeskulls',
    'I think, therefore I am': 'i-think-therefore-i-am',
    'ibisNFT.json': 'ibisnft-json',
    imwiththealiens: 'imwiththealiens',
    'IRL Heroes': 'irl-heroes',
    isladiwa: 'isladiwa',
    "Je T'aime by Ja": 'je-t-aime-by-ja',
    JESArt: 'jesart',
    jetchicken: 'jetchicken',
    JollyRogersCrew: 'jollyrogerscrew',
    'Just Getting Started': 'just-getting-started',
    'Just Tweets': 'just-tweets',
    KaizenNFT: 'kaizennft',
    KamaZOOtra: 'kamazootra',
    KardanoKidz: 'kardanokidz',
    Karranka: 'karranka',
    'King of the Rats': 'king-of-the-rats',
    KingPopoI: 'kingpopoi',
    KirkMonster: 'kirkmonster',
    Kryptoids: 'kryptoids',
    KungFuQTZ: 'kungfuqtz',
    'Life in Lockdown': 'life-in-lockdown',
    LilGoats: 'lilgoats',
    'Low Poly Project': 'low-poly-project',
    MADinArt: 'madinart',
    Mario_Uranjek: 'mario-uranjek',
    MashasColourbook: 'mashascolourbook',
    MashaXGlus: 'mashaxglus',
    MaskedOnButtons: 'maskedonbuttons',
    Maya_S_Art: 'maya-s-art',
    'MCJ001 Block Explorers': 'mcj001-block-explorers',
    'MCJ002 ADA Summer': 'mcj002-ada-summer',
    Meens: 'meens',
    Meepo: 'meepo',
    Metablocks: 'metablocks',
    MetroMermaids: 'metromermaids',
    Microkins: 'microkins',
    'MONROE MORDOR': 'monroe-mordor',
    Monstrocity: 'monstrocity',
    'Mosie-Marks': 'mosie-marks',
    'Mr.Cooper': 'mr-cooper',
    MrIntangble: 'mrintangble',
    MrReier: 'mrreier',
    'Mycelial Gallery': 'mycelial-gallery',
    'Neon Nights': 'neon-nights',
    'Nervous Unsig Combinator': 'nervous-unsig-combinator',
    'Never Engine': 'never-engine',
    NFrogTs: 'nfrogts',
    'NFT Dropout': 'nft-dropout',
    'NFT Dropout - Crapheads': 'nft-dropout-crapheads',
    'NFT Update': 'nft-update',
    NFTea: 'nftea',
    NFTLocker: 'nftlocker',
    NFTsurf: 'nftsurf',
    NiftyTeddy: 'niftyteddy',
    NightShow: 'nightshow',
    'Non-Fungible POP': 'non-fungible-pop',
    NothingCollectibles: 'nothingcollectibles',
    'Omega Collection': 'omega-collection',
    Outpost: 'outpost',
    OuttaSpace: 'outtaspace',
    'P@ndora': 'p-ndora',
    pablo: 'pablo',
    Pandalogy: 'pandalogy',
    PatingRATokens: 'patingratokens',
    'Pick Wiz': 'pick-wiz',
    PixanoNFT: 'pixanonft',
    'Pixel Tiles': 'pixel-tiles',
    PixieNFT: 'pixienft',
    PizzaNFTs: 'pizzanfts',
    planet_unsigs: 'planet-unsigs',
    PlanetPalz: 'planetpalz',
    'Planum - Cellular Biology': 'planum-cellular-biology',
    'politikoz.json': 'politikoz-json',
    Ponchos: 'ponchos',
    'Professor Cardano': 'professor-cardano',
    ProjectDystoblock: 'projectdystoblock',
    PROJECTLSD: 'projectlsd',
    PsychADAlias: 'psychadalias',
    'PsychAlgodelicUnsigs.json': 'psychalgodelicunsigs-json',
    PumpkinCatz: 'pumpkincatz',
    'Punk Baby House Party': 'punk-baby-house-party',
    'put your hands up': 'put-your-hands-up',
    'PXL-ERGnomes': 'pxl-ergnomes',
    Rabbitlung: 'rabbitlung',
    'Rain Dance Eye': 'rain-dance-eye',
    RastaPunks: 'rastapunks',
    RawPNG: 'rawpng',
    'ReiKo Appreciation Collection': 'reiko-appreciation-collection',
    RetroNFTs: 'retronfts',
    RForce: 'rforce',
    'Rhino Generation': 'rhino-generation',
    RobinBlizzGlus: 'robinblizzglus',
    Rotatoes: 'rotatoes',
    RoyalMcHumpin: 'royalmchumpin',
    rwpnft: 'rwpnft',
    SafariaWorld: 'safariaworld',
    'SaL Art': 'sal-art',
    Samurai: 'samurai',
    SaneroRai: 'sanerorai',
    SantoNFT: 'santonft',
    SantoToken: 'santotoken',
    'Screen Printed Skull': 'screen-printed-skull',
    shamix3d: 'shamix3d',
    ShapesADA: 'shapesada',
    'Shark Awareness Day': 'shark-awareness-day',
    SharkNFTs: 'sharknfts',
    'Sketch Heroes': 'sketch-heroes',
    SMALLBBRO: 'smallbbro',
    'Sneakers x MiMo x Specter': 'sneakers-x-mimo-x-specter',
    SoccaDoodz: 'soccadoodz',
    'Society Laid Bear': 'society-laid-bear',
    "Sophie's Gallery": 'sophie-s-gallery',
    SpaceBudz: 'spacebudz',
    spacecoins: 'spacecoins',
    SpaceHorses: 'spacehorses',
    'Specter Collection': 'specter-collection',
    'Spherical Spheres': 'spherical-spheres',
    'spread_love.json': 'spread-love-json',
    'Stained Glass': 'stained-glass',
    Stale: 'stale',
    stellarhood: 'stellarhood',
    StereogramNFT: 'stereogramnft',
    'Stigma Cards': 'stigma-cards',
    'Stik Friks': 'stik-friks',
    Stiks: 'stiks',
    StoneAgeHooligans: 'stoneagehooligans',
    'stonks_br.json': 'stonks-br-json',
    'street_arts.json': 'street-arts-json',
    StupidTweets: 'stupidtweets',
    SuperFighterSSJ: 'superfighterssj',
    'SuperFighterSSJ - Goton': 'superfighterssj-goton',
    SushiBytes: 'sushibytes',
    Suwunk31: 'suwunk31',
    swankpie: 'swankpie',
    'Sweet Fellas': 'sweet-fellas',
    'TC cardanoNFT project': 'tc-cardanonft-project',
    'Tea Bag & Peach': 'tea-bag-peach',
    'Teddies Leaf': 'teddies-leaf',
    'text art: the human spectacle': 'text-art-the-human-spectacle',
    Thakargc: 'thakargc',
    Thakargc35: 'thakargc35',
    'The Atomic Heroes': 'the-atomic-heroes',
    'The Blind Girl': 'the-blind-girl',
    'The Hoskinsons': 'the-hoskinsons',
    'The Pasta Society': 'the-pasta-society',
    'The Sheriff': 'the-sheriff',
    'The Slysons': 'the-slysons',
    TheAttackOnVitalik: 'theattackonvitalik',
    theCryptoverse: 'thecryptoverse',
    TheGalgos: 'thegalgos',
    TheHoskinsons: 'thehoskinsons',
    TheLuckiestCharm: 'theluckiestcharm',
    TheOnadracs: 'theonadracs',
    ThePsychedelicOriginal: 'thepsychedelicoriginal',
    TheRokindoCollective: 'therokindocollective',
    TheRoninverse: 'theroninverse',
    TheSugarCrew: 'thesugarcrew',
    thiscrazylife: 'thiscrazylife',
    TigerSociety: 'tigersociety',
    'Time Trotters': 'time-trotters',
    'Titan Avatars': 'titan-avatars',
    'Titan Avatars: Balrog': 'titan-avatars-balrog',
    'Touréguide Policies': 'tour-guide-policies',
    Triptych: 'triptych',
    Trybbles: 'trybbles',
    UnbankTheWorld: 'unbanktheworld',
    'unsig creations': 'unsig-creations',
    'unsig-PXLZ': 'unsig-pxlz',
    UnsigMosh: 'unsigmosh',
    unsigned_algorithms: 'unsigned-algorithms',
    'Unsigned Teddys': 'unsigned-teddys',
    unsigSpace: 'unsigspace',
    'Update Cryptoknitties': 'update-cryptoknitties',
    'Update Dystoblock': 'update-dystoblock',
    'Update Dystoblock End S01': 'update-dystoblock-end-s01',
    'Update Dystoblock PolicyID': 'update-dystoblock-policyid',
    'Update PolicyID Dystoblock': 'update-policyid-dystoblock',
    'Updated Adamon': 'updated-adamon',
    'Updated Dystoblock': 'updated-dystoblock',
    USNavyMKV: 'usnavymkv',
    'VanityTrophies.json': 'vanitytrophies-json',
    VanityTrophiesxcryp7ocr3ations: 'vanitytrophiesxcryp7ocr3ations',
    Vegas: 'vegas',
    VeryImportantDummies: 'veryimportantdummies',
    "ViralNFT's": 'viralnft-s',
    VisionAI: 'visionai',
    Visions: 'visions',
    VizDotLife: 'vizdotlife',
    WeAreOne: 'weareone',
    WenCardano: 'wencardano',
    'Witches&Fairies': 'witches-fairies',
    WizardCrypt: 'wizardcrypt',
    Yasen: 'yasen',
    YassinHassan: 'yassinhassan',
    YummiUniverse: 'yummiuniverse',
    Zombits: 'zombits'
  }

export { projectsList }