import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types";
import MetaTags from 'react-meta-tags';
import {
  Container, 
  Row,
  Col,
} from "reactstrap"

import maintanence from "../../assets/images/coming-soon.svg"

class Dashboard extends Component {
  render() {
    console.log(this.props.match.params.id)
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Cardano NFT spy</title>
          </MetaTags>
          <Container fluid>
            <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
            <Row className="justify-content-center mt-5">
              <Col lg="12">
                <div className="text-center">
                    <h4 className="mt-5">Hey welcome to the pre-party</h4>
                    <p className="text-muted">
                      We are still setting things up here and aggregating all the data we can
                    </p>
                    <p className="text-muted">
                      This means the site is current in beta mode and some things might not work as expected
                    </p>
                    <p>
                      Click on the button to see what we are working on
                    </p>
                    <Link to="/projects" type="button" className="btn btn-dark">
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      Click Me
                    </Link>
                </div>
              </Col>
            </Row>
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Dashboard.propTypes = {
  match: PropTypes.any,

}

export default Dashboard;
