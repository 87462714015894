import React from "react"
import { Card, CardBody, Container } from "reactstrap"
import MetaTags from 'react-meta-tags';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// import Component
import LeftBar from "./leftBar"
import Content from "./content"

const Index = (doc) => {
  const series = [76]
  const options = {
    chart: {
      height: 150,
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },

        hollow: {
          size: "60%",
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "16px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    stroke: {
      dashArray: 3,
    },
    labels: ["Storage"],
  }
  return (
    <React.Fragment>
      <div className="page-content">
          <MetaTags>
            <title>API Documentation</title>
          </MetaTags>
        <Container fluid>
          <Breadcrumbs title="API Documentation" breadcrumbItem={doc.doc} />
          <div className="d-xl-flex">
            <div className="w-100">
              <div className="d-md-flex">
                <LeftBar />
                <div className="w-100">
                  <Card>
                    <CardBody>
                      <Content doc={doc.doc} />
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
