import { call, put, takeEvery } from "redux-saga/effects"
const isNil = require('lodash/isNil')

// Crypto Redux States
import { GET_SEARCH } from "./actionTypes"
import { getSearchSuccess, getSearchFail } from "./actions"

import { getFirebaseBackend } from "../../helpers/firebase_helper"
const fireBaseBackend = getFirebaseBackend()

function* fetchSearch(params) {
  let ref = "Assets"
  if(params.ref === "sales") {
    ref = "Sales"
  }
  try {
    let response = yield call(fireBaseBackend.getObjectEqualTo, {
      ref: ref,
      orderByChild: params.key,
      equalTo: params.term
    })
    let data
    if(!isNil(response)){
      data = Object.keys(response).map((k) => response[k])
    } else {
      data = false;
    }
    yield put(getSearchSuccess(data))

  } catch (error) {
    yield put(getSearchFail(error))
  }
}

function* searchSaga() {
  yield takeEvery(GET_SEARCH, fetchSearch)
}

export default searchSaga