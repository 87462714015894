import { GET_SEARCH, GET_SEARCH_FAIL, GET_SEARCH_SUCCESS } from "./actionTypes"

export const getSearch = (searchTerm, searchType, searchKey) => ({
  type: GET_SEARCH,
  term: searchTerm,
  key: searchKey,
  ref: searchType
})

export const getSearchSuccess = search => ({
  type: GET_SEARCH_SUCCESS,
  payload: search,
})

export const getSearchFail = error => ({
  type: GET_SEARCH_FAIL,
  payload: error,
})
