import { GET_SALES_SUCCESS, GET_SALES_FAIL } from "./actionTypes"

const INIT_STATE = {
  sales: [],
  error: {},
  loading: true
}

const sales = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SALES_SUCCESS:
      return {
        ...state,
        sales: action.payload,
        loading: false

      }

    case GET_SALES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default sales
